@import 'styles/mixins';

.root {
  position: fixed;
  background-color: var(--palette-background-secondary);
  border-radius: 20px;
  z-index: 46;
}

.languageListItem {
  border-block-end: 1px solid var(--palette-divider);

  &:last-child {
    border-block-end: none;
  }
}
