@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  font-size: var(--dimensions-font-size-normal);
  color: var(--palette-text-hint);

  &:hover {
    color: var(--palette-primary-main);
  }
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.check {
  inline-size: 1.2rem;
  margin-inline: 0.2rem 0.5rem;
}

.language {
  font-size: var(--dimensions-font-size-normal);
  white-space: nowrap;
}

.country {
  display: none;

  @include md {
    display: block;
    font-size: var(--dimensions-font-size-badge);
    white-space: nowrap;
  }
}
