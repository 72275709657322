@import 'mixins';

html {
  overflow: hidden scroll;

  --palette-primary-main: #405767;
  --palette-primary-contrast-text: #fff;
  --palette-primary-light: var(--palette-primary-main);
  --palette-primary-dark: var(--palette-primary-main);
  --palette-secondary-main: #2bd675;
  --palette-secondary-contrast-text: #fff;
  --palette-secondary-light: var(--palette-secondary-main);
  --palette-secondary-dark: var(--palette-secondary-main);
  --palette-accent-main: var(--palette-secondary-main);
  --palette-accent-contrast-text: var(--palette-secondary-contrast-text);
  --palette-accent-light: var(--palette-accent-main);
  --palette-accent-dark: var(--palette-accent-main);
  --palette-interaction-main: var(--palette-accent-main);
  --palette-interaction-contrast-text: var(--palette-accent-contrast-text);
  --palette-interaction-light: var(--palette-accent-main);
  --palette-interaction-dark: var(--palette-accent-main);
  --palette-error-main: #f36;
  --palette-error-background: #f8dada;
  --palette-error-contrast-text: #7b0000;
  --palette-success-main: #2bd675;
  --palette-success-background: #f8f8fa;
  --palette-success-contrast-text: white;
  --palette-warning-main: #bc7100;
  --palette-warning-background: rgb(252 247 233);
  --palette-warning-contrast-text: #fff;
  --palette-info-main: #495867;
  --palette-info-background: #f8f8fa;
  --palette-info-contrast-text: #fff;
  --palette-background-default: #fff;
  --palette-background-secondary: #f8f8fa;
  --palette-background-hover: #e2e3ec;
  --palette-background-overlay: #cecfd8;
  --palette-background-switch: #ccc;
  --palette-divider: #e1e1e1;
  --palette-text-primary: #495867;
  --palette-text-default: #495867;
  --palette-text-secondary: #8d92a8;
  --palette-text-hint: #8d92a8;
  --palette-text-logo: #495867;
  --palette-black: #101010;
  --shadow-default: 0px 1px 5px rgb(150 150 150 / 30%);
  --shadow-hover: 0px 1px 10px 0px rgb(0 0 0 / 15%);
  --shadow-overlay: 0px 1px 5px rgb(106 106 106);
  --palette-transparent-overlay: rgb(255 255 255 / 70%);
  --palette-transparent-overlay-hover: rgb(255 255 255 / 90%);
  --dimensions-border-radius-card: 13px;
  --dimensions-border-radius-default: 4px;
  --dimensions-line-height-default: 1.2;
  --dimensions-font-size-small: 0.75rem;
  --dimensions-font-size-normal: 0.938rem;
  --dimensions-font-size-large: 1.25rem;
  --dimensions-font-size-heading1: 3.125rem;
  --dimensions-font-size-heading2: 2rem;
  --dimensions-font-size-heading3: 1.563rem;
  --dimensions-font-size-heading4: 1.25rem;
  --dimensions-font-size-heading5: 0.938rem;
  --dimensions-font-size-heading6: 0.625rem;
  --dimensions-font-size-button-normal: 0.875rem;
  --dimensions-font-size-button-large: 0.938rem;
  --dimensions-font-size-badge: 0.75rem;
  --animation-duration: 0.2s;
  --font-default:
    'Metropolis', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, helvetica, arial,
    sans-serif;
  --font-headings:
    'Metropolis', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, helvetica, arial,
    sans-serif;

  // @media (prefers-color-scheme: dark) {
  //   --palette-primary-main: #1C2451;
  //   --palette-primary-contrast-text: #fff;

  //   --palette-background-default: #24282C;
  //   --palette-background-secondary: #1D2125;
  //   --palette-background-hover: #29292f;
  //   --palette-background-overlay: #8888;

  //   --palette-divider: #44484C;

  //   --palette-text-primary: #FFF;
  //   --palette-text-default: #BBB;
  //   --palette-text-secondary: #999;
  //   --palette-text-hint: #8D92A8;
  //   --palette-text-logo: white;

  //   --shadow-default: 0px 1px 5px #0004;
  //   --shadow-hover: 0px 1px 10px 0px #0004;
  //   --shadow-overlay: 0px 1px 5px #0024;
  // }
}

html[data-mobile-app='true'] {
  header {
    display: none;
  }

  footer {
    display: none;
  }

  .mobile-app-hidden {
    display: none;
  }
}

html {
  .logged-in-only {
    display: none;
  }

  .logged-out-only {
    display: flex;
  }

  &[data-logged-in='true'] {
    .logged-in-only {
      display: flex;
    }

    .logged-out-only {
      display: none;
    }
  }

  &[data-embedded='true'] {
    .hidden-when-embedded {
      display: none;
    }
  }
}

body {
  padding-block: 0;
  padding-inline: 0;
  margin: 0;
  font-family: var(--font-default);
  scroll-behavior: smooth;
  background-color: var(--palette-background-default);
  color: var(--palette-text-default);
  font-size: var(--dimensions-font-size-normal);
}

a {
  color: inherit;
  color: var(--palette-interaction-main);
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-Regular'), url('/fonts/Metropolis-Regular.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-Medium'), url('/fonts/Metropolis-Medium.woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-SemiBold'), url('/fonts/Metropolis-SemiBold.woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-Bold'), url('/fonts/Metropolis-Bold.woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-ExtraBoldItalic'), url('/fonts/Metropolis-ExtraBoldItalic.woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-RegularItalic'), url('/fonts/Metropolis-RegularItalic.woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-Black'), url('/fonts/Metropolis-Black.woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Londrina;
  src: local('Londrina-Solid'), url('/fonts/Londrina-Solid.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  letter-spacing: 0;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  background: inherit;
}

p,
a,
span,
button,
input,
select,
label,
li,
table {
  letter-spacing: -0.03em;
  padding: 0;
  margin: 0;
}

p {
  margin-block: 0.8rem;
  margin-inline: 0;
  line-height: 1.3;
}

[hidden] {
  display: none !important;
}
