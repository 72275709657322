@import 'styles/mixins';

.root {
  display: block;
  position: fixed;
  inset-block-end: 0;
  inline-size: 100%;
  padding: 0;
  opacity: 1;
  z-index: 48;
  color: var(--palette-text-default);
  background: #fff;
  border-block-start: 1px solid var(--palette-divider);

  nav {
    display: flex;

    @include sm {
      display: none;
    }

    figure {
      position: relative;
      display: block;
      inline-size: 25%;

      figcaption {
        text-align: center;
        text-transform: none;
      }
    }
  }
}
