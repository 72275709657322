@import 'styles/mixins';

.root {
  @include sm {
    background-color: var(--palette-background-secondary);
    border-block-start: 1px solid var(--palette-divider);
  }

  .footerContainer {
    display: none;

    @include sm {
      display: grid;
    }

    grid-template-columns: repeat(12, 1fr);
    justify-items: start;
    column-gap: 2em;
    padding-block: 32px 0;

    @include container-full-width;
  }

  a:link,
  a:hover,
  a:visited,
  a:active {
    color: var(--palette-text-secondary);
  }

  h5 {
    text-transform: uppercase;
    margin-block: 0 1rem;
    margin-inline: 0;
  }

  .privacy {
    grid-column: 1 / span 4;
    display: flex;

    .footerLogoColumn {
      flex: 0 0 100px;

      svg {
        display: block;
        inline-size: 70px;
        block-size: 70px;
      }
    }

    p {
      font-size: 0.938rem;
    }
  }

  .aboutUs {
    grid-column: 5 / span 2;

    a {
      display: block;
      margin-block-end: 0.5rem;
    }
  }

  .needHelp {
    grid-column: 7 / span 2;

    a {
      display: block;
      margin-block-end: 0.5rem;
    }
  }

  .apps {
    grid-column: 9 / span 2;
    display: none;
    margin-block-end: 1rem;

    a {
      display: block;
      margin-block-end: 0.5rem;
      block-size: 50px;

      svg {
        display: block;
        inline-size: 100%;
        block-size: 100%;
      }
    }

    &.showAppLinks {
      display: block;
    }
  }

  .connect {
    grid-column: 9 / span 2;

    .socialMedia {
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-block-start: 1rem;

      a {
        margin-inline-end: 1rem;
        border-radius: 50%;
        padding: 0.625rem;
        background-color: var(--palette-background-overlay);
        color: var(--palette-primary-main);

        svg {
          inline-size: 1rem;
          block-size: 1rem;
          display: block;
        }
      }
    }
  }

  .languagePicker {
    grid-column: 12 / span 1;

    @include md {
      grid-column: 11 / span 2;
    }
  }

  .moreLinks {
    grid-column: 1 / span 12;
    display: none;

    @include sm {
      display: flex;
    }

    flex-wrap: nowrap;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    inline-size: 100%;
    border-block-start: 1px solid var(--palette-divider);
    padding-block-start: 1rem;
    margin-block: 2rem;
    margin-inline: 0;

    .cookiePreferencesButton {
      color: var(--palette-text-secondary);
      text-transform: revert;
      font-weight: 400;
      font-size: var(--dimensions-font-size-normal);
      line-height: unset;
    }
  }

  .copyright {
    display: none;

    @include sm {
      display: block;
    }

    grid-column: 1 / span 12;
    inline-size: 100%;
    margin-block-start: 2rem;
    font-size: 0.938rem;
    color: var(--palette-primary-contrast-text);
    background-color: var(--palette-primary-main);
    text-align: center;

    p {
      padding-block: 1rem;
      padding-inline: 0;
      margin: 0;
    }
  }
}
