div.root {
  inline-size: 100%;

  div.sidebar {
    inline-size: 80%;
    transition: 0.2s;
  }

  &[data-open='false'] {
    inline-size: 0;
    overflow-x: hidden;

    div.sidebar {
      inline-size: 0;
      transition: 0.2s;
    }

    .blocker {
      display: none;
    }
  }
}

div.sidebar {
  background-color: #fff;
  block-size: 100%;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow-x: hidden;
  padding-block-start: 1.5rem;
  z-index: 1035;
  box-shadow: 0 0 rgb(0 0 0 / 50%);

  .menuClose {
    margin-block: 0;
    margin-inline: 0.8rem;

    a {
      float: inline-end;
      color: #000;
      text-shadow: 0 1px 0 #fff;

      &:hover {
        opacity: 0.2;
      }

      svg {
        filter: invert(30%) sepia(33%) saturate(319%) hue-rotate(154deg) brightness(98%)
          contrast(90%);
      }
    }
  }

  nav {
    padding-block: 0;
    padding-inline: 0.6rem;

    a {
      display: block;
      font-size: var(--dimensions-font-size-small);
      line-height: var(--dimensions-line-height-default);
      padding-block: 0.6rem;
      padding-inline: 1rem;
      color: var(--palette-text-default);

      &:active,
      &:hover {
        color: inherit;
      }

      &.who,
      &.signout {
        padding-block-start: 1.5rem;
        border-block-start: 1px solid var(--palette-divider);
        margin-block-start: 1.5rem;
      }

      svg {
        font-size: 1.2rem;
        margin-inline-end: 0.5rem;
        vertical-align: middle;
      }
    }
  }

  .connect {
    padding-block: 2rem;
    padding-inline: 1.5rem;

    .socialMedia a {
      background: rgb(185 185 185 / 16%);
      margin-inline-end: 0.4rem;
      padding-block: 0.5rem;
      padding-inline: 0.5rem;
      border-radius: 100%;
      color: var(--palette-primary-main);
    }
  }

  .signout {
    text-transform: uppercase;

    svg {
      vertical-align: bottom;
    }
  }
}

.blocker {
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  inline-size: 100%;
  block-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 1030;
}
