@use 'sass:map';
@import 'mixins';

// Colors: https://www.kantardesignlab.io/lp-framework/colors.html
$color-indego: (
  'light-1': #bec4e3,
  'light-2': #7885cb,
  'dark-1': #3f51b5,
  'dark-2': #303f9f,
);
$color-teal: (
  'light-1': #9ff7de,
  'light-2': #0feaac,
  'dark-1': #25b48b,
  'dark-2': #098763,
);
$color-deep-orange: (
  'light-1': #ffcdb2,
  'light-2': #ff8c4c,
  'dark-1': #ff5b00,
  'dark-2': #d54c00,
);
$color-cyan: (
  'light-1': #a4f4f6,
  'light-2': #1ce3e8,
  'dark-1': #30b0b3,
  'dark-2': #0e8386,
);
$color-amber: (
  'light-1': #fe9,
  'light-2': #ffd400,
  'dark-1': #efc805,
  'dark-2': #8c7503,
);
$color-blue-grey: (
  'light-1': #f5f7f8,
  'light-2': #4d6a81,
  'dark-1': #405464,
  'dark-2': #001627,
);
$color-magenta: (
  'light-1': #ff67a9,
  'light-2': #ed0082,
  'dark-1': #b72062,
  'dark-2': #a10d4e,
);
$color-orange: (
  'light-1': #ffcc80,
  'light-2': #ff9d00,
  'dark-1': #ea9212,
  'dark-2': #a86800,
);
$color-green: (
  'light-1': #e0f798,
  'light-2': #b3ed00,
  'dark-1': #98b715,
  'dark-2': #638015,
);
$color-grey: (
  'light-1': #fafafa,
  'light-2': #b9b9b9,
  'dark-1': #515151,
  'dark-2': #000,
);
$color-neutral1: (
  'light-1': #f5e6d7,
  'light-2': #e6d3bd,
  'dark-1': #d1c4b1,
  'dark-2': #b79e8c,
);
$color-neutral2: (
  'light-1': #e9e1da,
  'light-2': #ded5cd,
  'dark-1': #c6bdb3,
  'dark-2': #b5a596,
);
$color-primary: map.get($color-indego, 'dark-1');
$color-primary-variant: map.get($color-indego, 'dark-2');
$color-secondary: map.get($color-teal, 'light-2');
$color-secondary-variant: map.get($color-teal, 'dark-1');
$color-surface: #fff;
$color-background: map.get($color-blue-grey, 'light-1');
$color-text-on-light-background: map.get($color-blue-grey, 'dark-2');
$color-background-variant: map.get($color-grey, 'dark-2');
$color-error: map.get($color-magenta, 'dark-1');
$color-baseline-on-background: map.get($color-blue-grey, 'dark-2');
$color-baseline-on-surface: map.get($color-blue-grey, 'dark-2');
$color-icon-on-surface: #455a64;

html body {
  --palette-primary-light: #{map.get($color-indego, 'light-1')};
  --palette-primary-main: #{map.get($color-indego, 'dark-1')};
  --palette-primary-dark: #{map.get($color-indego, 'dark-2')};
  --palette-primary-contrast-text: #{$color-surface};
  --palette-secondary-light: #{map.get($color-blue-grey, 'light-1')};
  --palette-secondary-main: #{map.get($color-teal, 'light-2')};
  --palette-secondary-dark: #{map.get($color-grey, 'dark-1')};
  --palette-secondary-contrast-text: #{$color-text-on-light-background};
  --palette-accent-light: #{map.get($color-teal, 'light-1')};
  --palette-accent-main: #{map.get($color-teal, 'light-2')};
  --palette-accent-dark: #{map.get($color-teal, 'dark-2')};
  --palette-accent-contrast-text: #{$color-surface};
  --palette-error-main: #{$color-error};
  --palette-error-background: #{$color-surface};
  --palette-error-contrast-text: #{map.get($color-grey, 'dark-1')};
  --palette-success-main: #{map.get($color-teal, 'dark-1')};
  --palette-success-background: #{$color-surface};
  --palette-success-contrast-text: #{map.get($color-grey, 'dark-1')};
  --palette-warning-main: #{map.get($color-orange, 'dark-1')};
  --palette-warning-background: #{$color-surface};
  --palette-warning-contrast-text: #{map.get($color-grey, 'dark-1')};
  --palette-info-main: #{map.get($color-blue-grey, 'dark-1')};
  --palette-info-background: #{$color-surface};
  --palette-info-contrast-text: #{map.get($color-grey, 'dark-1')};
  --palette-background-default: #{map.get($color-blue-grey, 'light-1')};
  --palette-background-secondary: #{$color-surface};
  --palette-text-primary: #{map.get($color-blue-grey, 'dark-2')};
  --palette-text-default: #{map.get($color-blue-grey, 'dark-2')};
  --palette-text-secondary: #{$color-icon-on-surface};
  --palette-text-hint: #{map.get($color-blue-grey, 'light-2')};
  --palette-divider: #e7e7e7;
  --palette-interaction-light: rgb(255 255 255 / 82%);
  --palette-interaction-dark: #f5f5f5;
  --palette-background-overlay: #{map.get($color-grey, 'light-1')};
  --font-default:
    'Lato', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, helvetica, arial, sans-serif;
  --font-headings: 'Filson Soft', sans-serif;
  --dimensions-border-radius-default: 4px;
  --dimensions-font-size-normal: 0.9375rem;
  --dimensions-font-size-large: 1.25rem;
  --dimensions-font-size-heading1: 3.125rem;
  --dimensions-font-size-heading2: 2rem;
  --dimensions-font-size-heading3: 1.5625rem;
  --dimensions-font-size-heading4: 1.25rem;
  --dimensions-font-size-heading5: 0.9375rem;
  --dimensions-font-size-heading6: 0.625rem;
  --dimensions-font-size-button-normal: 0.875rem;
  --dimensions-font-size-button-large: 0.938rem;
  --dimensions-font-size-badge: 0.75rem;
  --shadow-default: inset 0 1px 1px rgb(0 0 0 / 7.5%);
  --shadow-hover: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%);
  --shadow-overlay: 0px 1px 5px rgb(106 106 106);
  --shadow-card:
    0 2px 2px 0 rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
}

@font-face {
  font-family: Lato;
  src: local('Lato-Regular'), url('/fonts/Lato-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato-Regular'), url('/fonts/Lato-Regular.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato-Bold'), url('/fonts/Lato-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Metropolis;
  src: local('Metropolis-Italic'), url('/fonts/Lato-Italic.ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Filson Soft';
  src:
    url('/fonts/filsonsoft-book-webfont.woff2') format('woff2'),
    url('/fonts/filsonsoft-book-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Soft';
  src:
    url('/fonts/filsonsoft-light-webfont.woff2') format('woff2'),
    url('/fonts/filsonsoft-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Soft';
  src:
    url('/fonts/filsonsoft-medium-webfont.woff2') format('woff2'),
    url('/fonts/filsonsoft-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Soft';
  src:
    url('/fonts/filsonsoft-bold-webfont.woff2') format('woff2'),
    url('/fonts/filsonsoft-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProThin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProBook.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProBookItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProRegularItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProMediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProBoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProHeavy.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProHeavyItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProBlack.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro';
  src: url('/fonts/filson-pro/FilsonProBlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueUltraLight.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueUltraLightItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueThin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueThinItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueLight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueLightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueRoman.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueMediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueBoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueHeavy.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueHeavyItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueBlack.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica-neue/HelveticaNeueBlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}
