@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;

  /* leave space for the footer menu */
  min-block-size: calc(100vh - 3.85rem);
  margin-block-end: 3.85rem;

  @include sm {
    min-block-size: 100vh;
    margin-block-end: 0;
  }

  > * {
    align-self: stretch;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
