@mixin heading($font-family, $color, $font-size) {
  font-family: $font-family;
  color: $color;
  font-size: $font-size;
  line-height: 1.1;
  font-weight: bold;
  margin-block: 0.8em;
  margin-inline: 0;
}

.h1 {
  @include heading(
    var(--font-headings),
    var(--palette-text-primary),
    var(--dimensions-font-size-heading1)
  );
}

.h2 {
  @include heading(
    var(--font-headings),
    var(--palette-text-primary),
    var(--dimensions-font-size-heading2)
  );
}

.h3 {
  @include heading(
    var(--font-headings),
    var(--palette-text-primary),
    var(--dimensions-font-size-heading3)
  );
}

.h4 {
  @include heading(
    var(--font-default),
    var(--palette-text-secondary),
    var(--dimensions-font-size-heading4)
  );
}

.h5 {
  @include heading(
    var(--font-default),
    var(--palette-text-secondary),
    var(--dimensions-font-size-heading5)
  );
}

.h6 {
  @include heading(
    var(--font-default),
    var(--palette-text-primary),
    var(--dimensions-font-size-heading6)
  );
}

.default {
}

.inverse {
  color: var(--palette-primary-contrast-text);
}
