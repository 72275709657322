a.link {
  color: var(--palette-primary-main);

  &:focus {
    color: var(--palette-primary-main);
  }

  &:hover {
    color: var(--palette-primary-main);
  }

  &:active {
    color: var(--palette-primary-main);
  }
}
