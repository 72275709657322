.root {
  position: absolute;
  inset-block-start: 3.875rem;
  inset-inline-end: 0;
  margin: 0;
  padding: 0;
  box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
  transition:
    opacity 0.2s ease-in-out,
    clip-path 0.2s ease-in-out;
  opacity: 0;
  clip-path: inset(-12px -12px 16rem -12px);

  li {
    list-style: none;

    a,
    a:link {
      display: block;
      padding-block: 12px;
      padding-inline: 16px 24px;
      color: var(--palette-text-secondary);
      background-color: var(--palette-background-secondary);
      white-space: nowrap;
      text-align: start;

      &:hover {
        color: var(--palette-text-secondary);
        background-color: var(--palette-background-overlay);
      }
    }
  }
}
