.root {
  margin: 0;
  position: relative;

  a,
  .dropdownWrapper {
    text-align: center;
    padding-block: 10px;
    padding-inline: 1em;
    display: flex;
    flex-direction: column;
  }

  svg {
    inline-size: 100%;
    block-size: 24px;
    color: var(--palette-text-secondary);
  }

  figcaption {
    text-transform: uppercase;
    color: var(--palette-text-secondary);
    font-size: 0.75rem;
    letter-spacing: normal;
  }

  &:hover {
    svg,
    figcaption {
      color: var(--palette-text-secondary);
    }
  }

  &.active {
    a,
    svg,
    figcaption {
      color: var(--palette-primary-main);
    }
  }
}
